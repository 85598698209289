<template>
  <div style="height: 50vh;" class="pt-5 text-vertical-center text-center">
    <svg
      width="177"
      height="35"
      viewBox="0 0 177 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Logo Tigros</title>
      <path
        d="M62.3999 21.4H68.3999V22.8C68.3999 24.6 68.3999 24.6 66.7999 25.4C66.6999 25.4 66.6999 25.5 66.4999 25.5C64.6999 26.4 62.6999 26.5 60.6999 26.1C58.0999 25.5 56.2999 24.1 55.2999 21.7C54.2999 19.7 54.2999 17.6 54.5999 15.5C55.2999 11.7 58.3999 8.99999 62.2999 8.79999C65.2999 8.69999 67.9999 9.39999 70.6999 10.5C70.9999 10.6 71.3999 10.8 71.6999 10.9L67.8999 1.19999C66.9999 1.09999 66.4999 0.899988 65.5999 0.899988C60.8999 0.299988 56.3999 0.899988 52.1999 3.59999C49.4999 5.29999 47.4999 7.59999 46.1999 10.4C44.5999 14.1 44.3999 18.1 45.2999 22C46.8999 27.8 50.5999 31.5 56.1999 33.2C59.1999 34.2 62.1999 34.2 65.2999 33.9C70.0999 33.3 74.0999 31.3 77.0999 27.5C77.1999 27.4 77.3999 26.9 77.3999 26.8C77.3999 22.8 77.3999 18.8 77.3999 14.9V14.5H62.1999V21.3H62.3999V21.4Z"
        fill="#013067"
      />
      <path d="M41.1004 1.20001H31.9004V33.6H41.1004V1.20001Z" fill="#013067" />
      <path
        d="M176 19C174.9 16.4 172.9 14.9 170.2 14.2C168.5 13.8 166.8 13.5 165.1 13.1C164 12.8 163 12.7 161.8 12.2C160.5 11.6 160.4 9.89999 161.5 9.09999C162.2 8.49999 163.1 8.4 163.8 8.4C165.4 8.3 167.4 8.49999 168.8 9.09999C170.4 9.69999 171.9 10.4 172.9 10.7L169.2 1.49999C168.3 1.39999 167.8 1.09999 166.9 1.09999C163.9 0.799995 161.1 0.799993 158.2 1.79999C155.9 2.69999 154.2 3.89999 152.9 5.89999C151.6 7.89999 151.2 9.99999 151.2 12.3C151.2 13.7 151.5 15.1 152.3 16.4C153.3 18 154.7 18.8 156.4 19.5C158.2 20.2 160.1 20.5 162.1 20.9C163.2 21.2 164.4 21.3 165.5 21.8C166.5 22.1 167.1 22.9 167.1 24.1C167.1 25.1 166.5 25.8 165.5 26.2C164.4 26.8 163.2 26.8 162.1 26.8C160.3 26.7 158.5 26.1 156.8 25.4C155.5 24.8 154.2 24.1 153 23.4C152 25.7 151 27.9 150 30.2C152.4 32 155.1 33.3 158.1 33.9C161.7 34.6 165.3 34.5 168.9 33.5C170.5 33.1 171.9 32.2 173.2 31.1C175 29.4 176 27.4 176.3 25C177 22.8 176.9 20.8 176 19Z"
        fill="#013067"
      />
      <path
        d="M137.2 23.5C135.8 25.1 134.1 25.9 131.9 26.1C130.2 26.2 128.6 26.1 127.1 25.4C124.7 24.3 123.3 22.3 122.7 19.7C122.3 17.7 122.3 15.7 123.1 13.7C124.2 10.9 126.4 9.30001 129.3 8.70001C129.7 8.60001 130.3 8.60001 130.6 8.60001C133.4 8.60001 135.7 9.60001 137.4 11.7C138.7 13.3 139.1 15.3 139.2 17.2C139.4 19.5 138.8 21.8 137.2 23.5ZM137.2 1.60001C134.4 0.600013 131.2 0.600012 128.3 0.900012C125 1.20001 122.2 2.30001 119.6 4.20001C117.5 5.80001 115.8 7.80001 114.6 10.3C113 13.7 112.8 17.3 113.5 21C114.5 26.1 117.3 29.9 122 32.4C124.7 33.8 127.7 34.2 130.8 34.4C131.8 34.3 132.8 34.3 133.8 34.1C136.6 33.8 139.2 33 141.6 31.3C145.6 28.6 147.8 24.9 148.4 20.2C149 16.4 148.4 12.7 146.6 9.10001C144.5 5.20001 141.2 2.90001 137.2 1.60001Z"
        fill="#013067"
      />
      <path
        d="M16.4998 9.20001C18.2998 9.20001 19.8998 10.8 19.8998 12.6V33.8H10.6998V12.6C10.6998 10.8 9.0998 9.20001 7.2998 9.20001H0.799805V1.20001H25.1998L28.2998 9.20001H19.8998H16.4998Z"
        fill="#013067"
      />
      <path
        d="M100.6 12C100.5 13.8 99.5002 14.8 97.5002 15C95.5002 15.1 93.5002 15.1 91.5002 15.1V8.29999C93.1002 8.29999 94.6002 8.29999 96.0002 8.29999C96.9002 8.29999 97.7002 8.39999 98.6002 8.69999C100 9.19999 100.7 10.4 100.6 12ZM105 20.5C107 19.4 108.6 17.8 109.3 15.5C109.9 13.9 110 12.2 109.9 10.5C109.6 6.49999 107.6 3.69999 103.8 2.29999C102 1.59999 100.1 1.29999 98.3002 1.29999C93.0002 1.29999 87.8002 1.29999 82.5002 1.29999C82.4002 1.29999 82.4002 1.29999 82.2002 1.29999V33.7H91.4002V22.2C91.8002 22.2 92.3002 22.2 92.8002 22.2C93.1002 22.2 93.4002 22.3 93.5002 22.6C95.8002 26.2 98.2002 29.7 100.6 33.4C100.7 33.7 100.9 33.8 101.3 33.8C104.6 33.8 107.8 33.8 111.2 33.8C111.3 33.8 111.5 33.8 111.6 33.8C108.6 29.7 105.8 25.7 102.8 21.6C103.6 21.1 104.3 20.8 105 20.5Z"
        fill="#013067"
      />
    </svg>
    <h1 class="font-weight-bold">Pagina non trovata!</h1>
    <h3>Ci dispiace. La pagina che hai cercato non è stata trovata.</h3>
    <h4>Ti invitiamo a effettuare una nuova ricerca.</h4>
    <v-btn
      color="primary"
      depressed
      style="text-decoration: none"
      class="font-weight-bold"
      :to="{ name: 'Home' }"
    >
      TORNA ALLA HOMEPAGE
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "NotFound"
};
</script>
<style lang="scss">
.not-found {
  .text-vertical-center {
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .text-vertical-center h1 {
    margin: 0;
    padding: 0;
    color: $primary;
    margin: 0;
    padding: 18px 0;
    font: 900 35px;
  }
  .text-vertical-center h3 {
    margin: 0;
    padding: 5px 0;
    font: 800 22px;
  }
  .text-vertical-center h4 {
    margin: 0;
    padding: 0 0 30px 0;
    font: 400 20px;
  }
}
</style>
